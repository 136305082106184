.text-red {
  color: red !important;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: white !important;
}
.App {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  text-align: center;
  background-color: white;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-body {
  overflow: overlay;
}

.loadingAnimation {
  height: 20%;
  width: 20%;
}

.modal-backdrop {
  width: 100% !important;
}
.modal-content {
  width: 100% !important;
  border-radius: 15px;
}
.modal-dialog {
  max-width: 70%;
}
.modal-lg {
  width: 100% !important;
}
.modal-open {
  overflow: hidden !important;
}

.modal-class-product {
  overflow: hidden !important;
}

@media (min-width: 1000px) {
  .modal-content-product {
    max-height: 95vh;

  }
  .modal-content-product .modal-body {
    overflow: overlay;
  }
}

.Select-menu-outer {
  z-index: 999 !important;
}
.Select.is-open {
  position: relative;
  z-index: 1000;
}

@media (max-width: 1000px) {
  .Toastify__toast-container {
    bottom: 0 !important;
  }
  .loadingAnimation {
    height: 40% !important;
    width: 40% !important;
  }
  .modal-lg {
    height: 100% !important;
  }
  .modal-dialog {
    max-width: 100% !important;
    height: 100%;
    margin: 0 !important;
  }
  .modal-content {
    height: 100% !important;
    border-radius: 0;
  }
  .modal-title {
    flex: 1 !important;
    justify-content: space-around !important;
  }
  .modal-footer {
    padding: 1rem !important;
  }

  .modal-body {
    overflow: overlay !important;
  }
  .modal-open .modal {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .card-body {
    overflow: overlay;
  }
}
.no-overflow {
  overflow: hidden !important;
}

h4 {
  font-size: 1.375rem;
  margin-bottom: 0.5rem;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
}
h1 {
  font-size: 2.25rem;
  margin-bottom: 0.5rem;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
}

.small {
  font-size: 1rem;
  font-weight: 300;
}

.noBackground .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent !important;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0.3rem;
  outline: 0;
}
